import logoDark from "@/images/logo.png";
import servicedetails1 from "@/images/services/service-details.jpg";
import slideOneImage2 from "@/images/slider/Pultruded.jpg";
import slideOneImage1 from "@/images/slider/Modular.jpg";
import slideOneImage3 from "@/images/slider/Natural.jpg";
import slideOneImage4 from "@/images/slider/slider-4.jpg";
import slideOneImage5 from "@/images/slider/slider-5.jpg";
import serviceImage1 from "@/images/services/service-1.jpg";
import serviceImage2 from "@/images/services/service-2.jpg";
import serviceImage3 from "@/images/services/service-3.jpg";
import productImage1 from "@/images/products/modularfrp.jpg";
import modularImage1 from "@/images/products/modularfrp.jpg";
import modularImage2 from "@/images/products/modularfrp2.jpg";
import modularImage3 from "@/images/products/modularfrp3.jpg";
import modularImage4 from "@/images/products/modularfrp4.jpg";
import modularImage5 from "@/images/products/modularfrp5.jpg";
import productImage2 from "@/images/products/pultrudedfrp.jpg";
import pultrudedImage1 from "@/images/products/pultrudedfrp.jpg";
import pultrudedImage2 from "@/images/products/pultrudedfrp2.jpg";
import pultrudedImage3 from "@/images/products/pultrudedfrp3.jpg";
import pultrudedImage4 from "@/images/products/pultrudedfrp4.jpg";
import pultrudedImage5 from "@/images/products/pultrudedfrp5.jpg";
import productImage3 from "@/images/products/natural.jpg";
import naturalImage1 from "@/images/products/natural.jpg";
import naturalImage2 from "@/images/products/natural2.jpg";
import naturalImage3 from "@/images/products/natural3.jpg";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/gallery/gallery-1.jpg";
import galleryOne2 from "@/images/gallery/gallery-2.jpeg";
import galleryOne3 from "@/images/gallery/gallery-3.jpg";
import galleryOne4 from "@/images/gallery/gallery-4.jpg";
import galleryOne5 from "@/images/gallery/gallery-5.jpg";
import galleryOne6 from "@/images/gallery/gallery-6.jpg";
import testimonial1 from "@/images/testimonial/testimonial-1.jpg";
import testimonial2 from "@/images/testimonial/testimonial-2.jpg";
import testimonial3 from "@/images/testimonial/testimonial-3.jpg";
import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import blog4 from "@/images/blog/blog-4.jpg";
import blog5 from "@/images/blog/blog-5.jpg";
import blog6 from "@/images/blog/blog-6.jpg";
import team1 from "@/images/team/team-1.jpg";
import team2 from "@/images/team/team-2.jpg";
import team3 from "@/images/team/team-3.jpg";
import team4 from "@/images/team/team-4.jpg";
import comment1 from "@/images/comment-1.jpg";
import comment2 from "@/images/comment-2.jpg";
import private1 from "@/images/clients/industries/1.jpg";
import public1 from "@/images/clients/government/2.jpg";
import public2 from "@/images/clients/government/3.jpg";
import public3 from "@/images/clients/government/4.jpg";
import public4 from "@/images/clients/government/5.jpg";
import private2 from "@/images/clients/industries/6.jpg";
import client7 from "@/images/clients/industries/7.jpg";
import public5 from "@/images/clients/government/8.jpg";
import approved1 from "@/images/clients/major/jp.jpg";
import approved2 from "@/images/clients/major/thy.jpg";
import private3 from "@/images/clients/industries/2.png";
import private4 from "@/images/clients/industries/12.jpg";
import private5 from "@/images/clients/industries/atul.jpg";
import private6 from "@/images/clients/industries/wte.jpg";
import approved3 from "@/images/clients/major/uttam.jpg";
import approved4 from "@/images/clients/major/vsi.jpg";


export const Logo = {
  dark: logoDark
};

export const NavLinksData = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "About",
    url: "/about"
  },
  {
    name: "Products",
    subItems: [
      {
        name: "Modular FRP Cooling Towers",
        url: "/products/modular-frp-cooling-towers"
      },
      {
        name: "Pultruded FRP Cooling Towers",
        url: "/products/pultruded-frp-cooling-towers"
      },
      {
        name: "Natural Draught Cooling Towers",
        url: "/products/natural-draught-cooling-towers"
      },
      {
        name: "More Services",
        url: "/service"
      }
    ]
  },
  {
    name: "Contact",
    url: "/contact"
  }
];




export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "66 Broklyn Street, New York",
    url: "#"
  },
  {
    icon: "icon-phone-call",
    tagLine: "Call Now",
    text: "666 888 000",
    url: "tel:666-888-000"
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#"
  }
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "Modular FRP cooling towers",
    text: "Choose the Best Company",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage2,
    title: "Field Erected Induced Draught Pultruded FRP Cooling Towers",
    text: "Reliable Air Conditioning",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage3,
    title: "Fan-less Cooling Towers",
    text: "Choose the Best Company",
    button: "GET A FREE QUOTE",
    url: "/contact"
  },
  {
    backgroundImage: slideOneImage3,
    title: "RCC Cooling Towers",
    text: "Choose the Best Company",
    button: "GET A FREE QUOTE",
    url: "/contact"
  }
];

export const SlideTwoData = [
  {
    backgroundImage: slideOneImage1,
    title: "Modular FRP Cooling Towers",
    button: "Know More",
    url: "/products/modular-frp-cooling-towers"
  },
  {
    backgroundImage: slideOneImage2,
    title: "Pultruded FRP Cooling Towers",
    button: "Know More",
    url: "/products/pultruded-frp-cooling-towers"
  },
  {
    backgroundImage: slideOneImage3,
    title: "Natural Draught Cooling Towers",
    button: "Know More",
    url: "/products/natural-draught-cooling-towers"
  }
];


// {
//   icon: "icon-wallet",
//   title: "No Upfront Payments"
// },
export const FeatureOneData = [
  {
    icon: "icon-snow",
    title: "Specially Curated Towers"
  },
  {
    icon: "icon-mechanic",
    title: "30 Years Experience & Expertise "
  },
  {
    icon: "icon-fire",
    title: "Prompt Emergency Services"
  },
  {
    icon: "icon-diploma",
    title: "Satisfaction Guaranteed"
  }
];

export const AboutOneData = {
  title: "We at Dew Pond have been consistently delivering reliable cooling tower solutions to all our clients from various sectors for the past 30 years.",
  tagLine: "Welcome to Dew Pond",
  content:
    "Flexibility in our designs has been central to meeting the exact requirements of your process. From high temperatures to extremely dusty environments, our wide range of cooling towers successfully cater all types of industries and applications.",
  button: {
    url: "/about",
    label: "LEARN MORE"
  }
};

export const ServiceOneData = {
  title: "",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Modification of Fill Pack",
      text: "The most common reason behind lack of performance in cooling towers is selection of inappropriate fill-pack. We have developed solutions in retrofitting efficient fill-packs without making any major modifications in the structure of the cooling tower.",
      button: {
        label: "LEARN MORE",
        url: "/service-details"
      }
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Conversion to P-FRP towers",
      text: "Old wooden cooling towers incur yearly repair and maintenance costs. P-FRP has proven as a superior alternative and we have undertaken multiple projects of conversion of wooden cooling towers to P-FRP cooling towers which has provided excellent results.",
      button: {
        label: "LEARN MORE",
        url: "/service-details"
      }
    }
  ]
};

export const CallToActionOneData = {
  title: "Free assessment and inspection",
  button: {
    url: "/contact",
    label: "CONTACT NOW"
  }
};

export const CallToActionTwoData = {
  left: {
    text: "Air Conditioning Services",
    title: "Cool it down"
  },
  right: {
    text: "Heating Services",
    title: "Heat it up"
  }
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "Our Stats",
  posts: [
    {
      count: 10,
      text: "Countries Reached"
    },
    {
      count: 3000,
      text: "Total Installations"
    },
    {
      count: 2000,
      text: "Satisfied Customers"
    }
  ]
};

export const GalleryOneData = [
  {
    title: "Maintenance and Repair",
    image: galleryOne1,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne2,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne3,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne4,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne5,
    url: "#"
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne6,
    url: "#"
  }
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO"
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer"
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director"
    },
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO"
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer"
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director"
    }
  ]
};

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-twitter",
      url: "#"
    },
    {
      icon: "fa fa-facebook-f",
      url: "#"
    },
    {
      icon: "fa fa-linkedin",
      url: "#"
    },
    {
      icon: "fa fa-youtube-play",
      url: "#"
    }
  ]
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "D-9 Murbad MIDC, Thane 421401",
    url: "#"
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "dewpond@gmail.com",
    url: "mailto:dewpond@gmail.com"
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#"
  }
];

export const FooterWidgets = {
  about: {
    logo: logoDark,
    title: "Dew Pond",
    text: "Dew Pond has been consistently delivering reliable cooling tower solutions to all our clients from various sectors for the past 30 years",
    tagLine: "Contact us",
    phone: "+91 95946-91566"
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "About Us",
        url: "/about"
      },
      {
        lable: "Modular FRP",
        url: "/products/modular-frp-cooling-towers"
      },
      {
        lable: "Pultruded FRP",
        url: "/products/pultruded-frp-cooling-towers"
      },
      {
        lable: "Natural Draught",
        url: "/products/natural-draught-cooling-towers"
      },
      {
        lable: "Other Services",
        url: "/service"
      },
      {
        lable: "Contact us",
        url: "/contact"
      }
    ]
  },
  linkTwo: {
    menu: [
      {
        lable: "Careers",
        url: "#"
      },
      {
        lable: "Privacy Policy",
        url: "#"
      },
      {
        lable: "Change Location",
        url: "#"
      },
      {
        lable: "Expert Tips",
        url: "#"
      },
      {
        lable: "Terms & Conditions",
        url: "#"
      }
    ]
  }
};

export const BlogOneData = {
  title: "Our latest news",
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog4,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Heating and cooling safety issues",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog5,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Don’t neglect your outdoor unit",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    },
    {
      image: blog6,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Have your system professionally inspected",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration."
    }
  ]
};

export const VideoOneData = {
  title: "Process Layout Placeholder",
  id: "8DP4NgupAhI"
};

export const AboutTwoData = {
  caption: " 30 <span>years experience</span> ",
  blockTitle: {
    title:
      "Our History",
    text: "Founded in 1988, by Mr. Sunil Deshpande (BE Mechanical) Incorporated in 1998 along with Mr. Mahesh Pathak (BE Mechanical) as a partner. Both having rich experience from working in multiple industries in the Public and Private sector were determined to venture and succeed in the cooling tower industry.",
    text2: "Started from a small workshop in Thane, Dew Pond now has a manufacturing unit spreading over 40,000 square feet in Murbad MIDC and corporate office in Wagle Estate, Thane. ",
    text3: "Dew Pond has always been about customer satisfaction. We strongly believe in educating and spreading awareness within our customers for them to be independent in their decision making. With an experienced service team of 25 people, we are equipped to handle any problem statements and offer solutions to obtain desirable results.",
    tagline: "Our History"
  },
  offer: {
    tagline: "10% off",
    text: "on our all services for senior citizens."
  }
};

// lists: [
//   {
//     text: " INDUCED DRAUGHT MODULAR FRP COOLING TOWERS"
//   },
//   {
//     text: " INDUCED DRAUGHT RCC COOLING TOWERS"
//   },
//   {
//     text: " INDUCED DRAUGHT TIMBER COOLING TOWERS"
//   },
//   {
//     text: " NATURAL DRAUGHT PULTRUDED FRP COOLING TOWERS"
//   },
//   {
//     text: " SPARE PARTS OF ALL COOLING TOWERS"
//   },
//   {
//     text: " AIR WASHERS & CHILLERS"
//   }
// ],

export const TeamOneData = {
  title: "Meet The Team",
  posts: [
    {
      image: team1,
      name: "Effie Green",
      designation: "Manager",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team2,
      name: "Catherine Lane",
      designation: "Worker",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team3,
      name: "Augusta Wells",
      designation: "Developer",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    },
    {
      image: team4,
      name: "Kyle Fuller",
      designation: "Founder",
      social: [
        {
          icon: "fa-facebook",
          url: "#"
        },
        {
          icon: "fa-twitter",
          url: "#"
        },
        {
          icon: "fa-youtube-play",
          url: "#"
        }
      ]
    }
  ]
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york"
  },
  summery:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    lable: "LEARN MORE",
    url: "/about"
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again"
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool"
    }
  ]
};

export const AboutFourData = {
  blockTitle: {
    title: "WE HAVE BUILT A RAPPORT WITH OUR CLIENTS WHICH HAS STAYED STRONG OVER A PERIOD OF <span>30</span> YEARS",
    text1:
      "DEW-POND MANUFACTURES ALL TYPES OF COOLING TOWERS TO CATER TO THE NEEDS OF THE INDUSTRY. \n OUR TEAM IS A MIXTURE OF YOUNG, DYNAMIC & EXPERIENCED PROFESSIONALS, WHO ARE PASSIONATE TO OFFER SOLUTIONS TO EVERY CHALLENGE OUR CLIENT FACES.",
    text2:
      "Founded by Mr. Sunil Deshpande (BE Mech) and Mr. Mahesh Pathak (BE Mech) in the year 1988, Dew Pond has been known for their excellent service and client relationships. Starting off in Wagle Estate Thane initially manufacturing Modular FRP cooling towers, Dew Pond steadily expanded their manufacturing capabilities and product range to RCC and Pultruded FRP Cooling Towers. In order to accommodate more large scale projects, Dew Pond is now setting up a new manufacturing unit in Murbad MIDC spreading over 40,000 square feet. Our team is a mixture of young, dynamic & experienced professionals,who are passionate to offer solutions to every challenge our client faces."
  }
};

export const BenefitOneData = {
  title: "Our benefits",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Emergency Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-wallet",
      title: "No Upfront Payment",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-diploma",
      title: "Satisfaction Guarantee",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    },
    {
      icon: "icon-mechanic",
      title: "8 Years Experience",
      text: "There are many passages of lorem ipsum available but the majority have alteration."
    }
  ]
};

export const ServiceSidebarListData = [
  {
    icon: "icon-big-ac",
    title: "Modular",
    list: [
      {
        label: "Modular FRP Cooling Towers",
        url: "/products/modular-frp-cooling-towers"
      },
      {
        label: "Pultruded FRP Cooling Towers",
        url: "/products/pultruded-frp-cooling-towers"
      },
      {
        label: "Ductless Split AC Systems",
        url: "#"
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#"
      }
    ]
  },
  {
    icon: "icon-ac",
    title: "Natural",
    list: [
      {
        label: "Modular FRP Cooling Towers",
        url: "/products/modular-frp-cooling-towers"
      },
      {
        label: "Pultruded FRP Cooling Towers",
        url: "/products/pultruded-frp-cooling-towers"
      },
      {
        label: "Natural Draught Cooling Towers",
        url: "/products/natural-draught-cooling-towers"
      },
      {
        label: "Other Services",
        url: "/service"
      }
    ]
  }
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Other Services",
    content: {
      icon: "icon-support",
      title: "<span>Contact us at </span>95946 91566"
    }
  },
  {
    icon: "icon-building",
    title: "Commercial Services",
    content: {
      icon: "icon-building",
      title: "<span>Contact us at </span>95946 91566"
    }
  }
];

export const CommentPostData = [
  {
    name: "David Martin",
    image: comment1,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  },
  {
    name: "Jessica Brown",
    image: comment2,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet."
  }
];

export const productDetails = {
  modularFrp: {
      title: 'Modular FRP Cooling Towers',
      banner: [modularImage1, modularImage2, modularImage3, modularImage4, modularImage5],
      description: [
        'Dew Pond offers a range of robust modular FRP cooling towers with custom fill pack options. Easiest to install, a single cell can handle water flow rate up to 350 CMH. Material of construction being FRP, these towers are extremely durable, with some of our installations comfortably running over a period of 20 years.'
      ],
      optionTitle: 'Features',
      options: [
          'Available in counterflow configuration',
          'Factory assembled cooling towers',
          'Can handle flow rates from 0.8 cmh to 500 cmh of water',
          'Dual fill-pack options can be provided film type and splash type',
          'Eliminate downtime for replacement of driveshaft bushes and Gearbox servicing with our direct driven fan',
          'Customizable material of construction like FRP, MSHDG, SS304 and Pultruded FRP to suit multiple environments and water pH',
          'Energy efficient fan assembly with VFD suitable motor',
          'Optional FRP basin',
          'Multiple cell configuration for larger flow rates.',
          'Can be used in conjunction with natural draught cooling towers as stage 2 for high temperature applications.'
      ],
      iconSection: true,
      icons: [
        {
          icon: "icon-building",
          title: "Malls Hospitals Hotels ",
        },
        {
          icon: "icon-building",
          title: "Automobile Food, Pharma industries",
        },
        {
          icon: "icon-building",
          title: "Power plants, Cement plants, Chemical plants",
        },
        {
          icon: "icon-building",
          title: "Plastic moulding, extrusion industries",
        }
      ],
      optionsFooter: '',
      faqQuestion: 'When should I repair or replace my modular cooling tower ?',
      faqOptions: [
        'Improper water distribution: Most common issue which arises due to choking of fills or nozzles.',
        'High drift loss: Arises due to damaged drift eliminators, to be attended on priority to avoid water loss',
        'Sudden rise in approach of cooling tower: Whenever there is a rise in approach at the same WBT, the cooling tower is not working at its design capabilities and needs assessment'
      ],
      faqFooter: 'Contact us for more Info..'
  },
  naturalDraught: {
      title: 'Natural Draught Cooling Towers',
      banner: [naturalImage1, naturalImage2, naturalImage3],
      description: [
        'Natural draught cooling towers are energy efficient Fan-Less, Fill-Less cooling tower in robust Pultruded FRP construction. Ideal for high temperature and non-critical applications.'
      ],
      optionTitle: 'Benefits',
      options: [
          'Fan-less, Fill-Less Cooling towers',
          'Material of construction : Pultruded FRP, SS 304, MS HDG can be chosen as per water quality',
          'Jet type nozzles',
          'Common Applications: ETP plants with water temperatures up to 80 d.c. or Light duty cooling applications'
      ],
      optionsFooter: '',
      faqQuestion: '',
      faqOptions: [],
      faqFooter: ''
  },
  pultrudedFrp: {
    title: 'Pultruded FRP Cooling Towers',
    banner: [pultrudedImage1, pultrudedImage2, pultrudedImage3, pultrudedImage4, pultrudedImage5],
    description: [
      'Lightweight, Corrosion resistant and long lasting, Pultruded FRP is currently the most sought out MOC in cooling towers. Superior in workability and durability, P-FRP is replacing wooden cooling towers across all industries. Available in both counterflow and cross flow options.'
    ],
    optionTitle: 'Features',
    options: [
        'Designed as per CTI standards',
        'Field Erected Cooling towers',
        'Material of construction: Pultruded FRP, Corrosion resistant, Light weight, Life upto 25 years',
        'Available in Counter and Cross Flow configuration'
    ],
    optionsFooter: '',
    faqQuestion: '',
    faqOptions: [],
    faqFooter: '',
    table: [
      [
        'Counter Flow Cooling towers',
        '1) Can handle up to 3500 CMH of water per cell',
        '2) Customisable size and orientation. Tailor-made to client’s requirements',
        '3) Available in multiple fill-pack options viz. Honeycomb Fills, PVC V bars, PP Opti Grids',
        '4) Common applications: Used for heavy duty cooling applications viz. Captive power plants, Evaporators, Process cooling requirements'
      ],
      [
        'Cross Flow Cooling towers',
        '1) Can handle up to 2000 CMH per cell',
        '2) Requires no pressure head at cooling tower inlet, works on gravity flow',
        '3) Splash type Fill-Pack',
        '4) Common applications:Used in challenging environments with bad water quality viz Distilleries, Refineries, Fertilizer/Chemical companies.'
      ]
    ]
  }
};


export const ProductOneData = {
  title: "Our Cooling Towers",
  posts: [
    {
      image: productImage1,
      icon: "icon-snow",
      title: "Modular FRP",
      text: "Dew Pond offers a range of robust modular FRP cooling towers with custom fill pack options. Easiest to install, a single cell can handle water flow rate up to 350 CMH. Material of construction being FRP, these towers are extremely durable, with some of our installations comfortably running over a period of 20 years.",
      button: {
        label: "LEARN MORE",
        url: "/products/modular-frp-cooling-towers"
      }
    },
    {
      image: productImage2,
      icon: "icon-fire",
      title: "Pultruded FRP",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "LEARN MORE",
        url: "/products/pultruded-frp-cooling-towers"
      }
    },
    {
      image: productImage3,
      icon: "icon-ac",
      title: "Natural Draught",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "LEARN MORE",
        url: "/products/natural-draught-cooling-towers"
      }
    }
  ]
};

export const allClients = {
  private: [
    private1,
    private2,
    private3,
    private4,
    private5,
    private6
  ],
  public: [
    public1,
    public2,
    public3,
    public4,
    public5
  ],
  approved: [
    approved1,
    approved2,
    approved3,
    approved4
  ]
};